import classNames from 'classnames'
import React from 'react'
import styled from 'styled-components'
import {
  rem,
  innerWidth,
  breakpoints,
  innerPadding,
  innerDummyPadding,
  color,
} from 'variables/_index'

export interface LeadFriendsSectionProps {
  readonly noProp?: string
}

type LeadFriendsSectionPropsClassName = LeadFriendsSectionProps & {
  readonly className: string
}

function Component({ className }: LeadFriendsSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-lead">
          お友達と一緒にオンラインで冬期講習を
          <br className="u-hidden-pc" />
          受講していただけます。
          <br />
          まずは、以下のフォームから体験ガイダンスをお申込みください。
        </div>
        <div className="e-points">
          <div className="e-point">
            <span className="u-words">スマホ・タブレット・PC</span>
            <span className="u-words">
              <span className="is-em">いずれか</span>があればOK。
            </span>
          </div>
          <div className="e-point">
            所要時間は<span className="is-em">約60分</span>
            <br />
            <span className="u-words">（サービス説明30分</span>
            <span className="u-words">+冬期講習の申込み30分）</span>
          </div>
        </div>
        <div className="e-lead-bottom">
          担当者から、体験ガイダンスの日時などの
          <br className="u-hidden-pc" />
          ご連絡をさせていただきます。
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<LeadFriendsSectionPropsClassName>`
  padding-top: ${rem(80 / 2)};
  padding-bottom: ${rem(40 / 2)};

  @media ${breakpoints.md} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(30)};
  }

  > .e-inner {
    position: relative;
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;

    @media ${breakpoints.md} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-head {
      width: fit-content;
      margin-inline: auto;
      font-size: ${rem(40 / 2)};
      color: ${color.main};
      font-weight: 500;
      letter-spacing: 0.2em;
      border-bottom: 2px solid ${color.main};

      @media ${breakpoints.md} {
        font-size: ${rem(32)};
      }
    }

    > .e-lead {
      text-align: center;
      font-size: ${rem(32 / 2)};
      line-height: 1.625;
      color: ${color.main};

      @media ${breakpoints.md} {
        font-size: ${rem(20)};
        width: fit-content;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
      }
    }

    > .e-points {
      margin-top: ${rem(20 / 2)};

      @media ${breakpoints.md} {
        margin-top: ${rem(20)};
      }

      > .e-point {
        padding: ${rem(5 / 2)} ${rem(26 / 2)} ${rem(5 / 2)};
        border-radius: ${rem(10 / 2)};
        border: 1px solid ${color.main};
        background-color: #f0ffff;
        font-size: ${rem(30 / 2)};
        color: ${color.main};
        line-height: 1.583;
        text-align: center;

        @media ${breakpoints.md} {
          font-size: ${rem(20)};
          width: ${rem(600)};
          padding-top: ${rem(5)};
          padding-bottom: ${rem(5)};
          margin-left: auto;
          margin-right: auto;
          border-radius: ${rem(10)};
        }

        &:nth-child(n + 2) {
          margin-top: ${rem(20 / 2)};

          @media ${breakpoints.md} {
            margin-top: ${rem(20)};
          }
        }

        .is-em {
          font-weight: 700;
        }
      }
    }

    > .e-lead-bottom,
    > .e-lead-bottom2 {
      margin-top: ${rem(20 / 2)};
      text-align: center;
      font-size: ${rem(32 / 2)};
      line-height: 1.625;
      color: ${color.main};

      @media ${breakpoints.md} {
        margin-top: ${rem(20)};
        font-size: ${rem(20)};
      }
    }

    .e-lead-bottom2 {
      font-weight: 700;
      margin-top: ${rem(16 / 2)};

      @media ${breakpoints.md} {
        margin-top: ${rem(10)};
      }
    }

    > .e-attention {
      margin-top: ${rem(50 / 2)};
      font-size: ${rem(26 / 2)};
      line-height: 2;

      @media ${breakpoints.md} {
        margin-top: ${rem(60)};
        font-size: ${rem(16)};
      }
    }
  }
`

export const LeadFriendsSection: (props: LeadFriendsSectionProps) => JSX.Element =
  StyledComponent

export default LeadFriendsSection
