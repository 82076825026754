import React, { useState, useEffect } from 'react'
import Seo from 'components/seo'
import Layout from 'layout/base'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'
import FriendsTemplate from 'templates/friendsTemplate'

interface FriendsPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const FriendsPage = ({ pageContext, location }: FriendsPageProps): JSX.Element => {
  const [userAgent, setUserAgent] = useState<string>('')

  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs
    .filter(crumb => {
      return crumb.crumbLabel !== 'form'
    })
    .map(crumb => {
      return crumb.crumbLabel in siteStructure
        ? {
            ...crumb,
            ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
          }
        : { ...crumb }
    })

  useEffect(() => {
    setUserAgent(window?.navigator?.userAgent ? window?.navigator?.userAgent : '取得失敗')
  }, [])

  return (
    <Layout pageType="page" isDisplayCTASection={false} breadcrumbItems={breadcrumbItems} isDisplayFixedFooter={false}>
      <Seo
        title={`おともだち紹介お申込みフォーム${globalTitle(location.pathname)}`}
        description="フィットネットスタディのおともだち限定紹介申し込みは、こちらからお問い合わせください。"
        path={location.pathname}
      />
      <FriendsTemplate headingText="おともだち紹介<br>お申込みフォーム" userAgent={userAgent} />
    </Layout>
  )
}

export default FriendsPage
