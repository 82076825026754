import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import type { SubmitHandler } from 'react-hook-form'
import Loading from 'components/section/form/components/loading'
import HeadSection from 'components/section/headSection'
import type { FormInputs } from 'types/form'
import encode from 'utils/encode'
import LeadFriendsSection from 'components/section/form/leadFriendsSection'
import FriendsFormSection from 'components/section/form/friendsFormSection'
import ThanksSection from 'components/section/form/thanksSection'

export interface FriendsTemplateProps {
  readonly headingText: string
  readonly userAgent: string
}

type FriendsTemplatePropsClassName = FriendsTemplateProps & {
  readonly className: string
}

function Component({ className, headingText, userAgent }: FriendsTemplatePropsClassName) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFriendsData, setIsFriendsData] = useState<boolean>(false)

  // エラーがなければ送信
  const onSubmit: SubmitHandler<FormInputs> = data => {
    setIsLoading(true)

    axios
      .post(
        '/',
        encode({
          'form-name': 'friends',
          formType: 'friends',
          userAgent: userAgent,
          ...data,
        }),
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      )
      .then(response => {
        if (typeof window !== 'undefined' && (window as any).dataLayer) {
          ;(window as any).dataLayer.push({ event: 'send-form-friends' })
        }
        setIsFriendsData(true)
        setIsLoading(false)
      })
      .catch(error => {
        // 失敗時の処理
        if (error) {
          setIsLoading(false)
        }
      })

    window.scroll({ top: 0 })
  }

  return (
    <div className={className}>
      <HeadSection
        pageHeadProps={{
          headingText: headingText,
          headingLevel: 'h1',
          isAuto: true,
        }}
      />

      {!isFriendsData ? (
        <>
          <LeadFriendsSection />
          <FriendsFormSection onSubmit={onSubmit} userAgent={userAgent} />
        </>
      ) : (
        <ThanksSection
          headElement={<>送信完了</>}
          bodyElement={
            <>
              <p>
                Fit NET STUDYへの体験申し込みを受け付けました。
                <br />
                3営業日以内に弊社よりメール・もしくはお電話にてご連絡差し上げます。
              </p>
              <p>【弊社からの連絡がない場合】</p>
              <p>お手数ですが、下記フリーコールまでお問い合わせください。</p>
              <p>
                0120-937-380
                <br />
                受付時間 9:00~21:00（日・祝を除く）
              </p>
            </>
          }
        />
      )}
      {isLoading && <Loading />}
    </div>
  )
}

const StyledComponent = styled(Component)<FriendsTemplatePropsClassName>``

export const FriendsTemplate: (props: FriendsTemplateProps) => JSX.Element = StyledComponent

export default FriendsTemplate
